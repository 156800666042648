import React from "react";
import { AuthContext } from "./context";
import Cookies from "universal-cookie";
import axios from "axios";

const AuthContextProvider = ({ children }) => {
	const [userInfo, setUserInfo] = React.useState(null);
	const [token, setToken] = React.useState(null);
	const [idInexplore, setIdInexplore] = React.useState(null);
	const [temporaryId, setTemporaryId] = React.useState(null);
	const [hasModerationAccess, setHasModeratorAccess] = React.useState(false);
	const [isModerator, setIsModerator] = React.useState(false);
	const [isSuperAdmin, setIsSuperAdmin] = React.useState(false);
	const [superAdminToken, setSuperAdminToken] = React.useState(null);
	const [isClosed, setIsClosed] = React.useState(false);

	const isAuthenticated = React.useMemo(() => {
		return !!token || !!userInfo || !!isSuperAdmin;
	}, [token, userInfo, isSuperAdmin]);

	const fetchLocalUser = () => {
		const user = localStorage.getItem("user");
		if (user) {
			setUserInfo(JSON.parse(user));
			axios.get(`${process.env.REACT_APP_API_URL}/user/id`).then((response) => {
				if (response.data) {
					setTemporaryId(response.data);
				}
			});
		}
	};

	React.useEffect(() => {
		const queryString = window.location?.pathname?.replace("/", "");
		const params = new URLSearchParams(window.location.search);
		const cookie = new Cookies();
		const id = cookie.get("INEXPLORE_ID");
		const token = cookie.get("INEXPLORE_TOKEN");
		const superAdminToken = params.get("adminToken");
		if(params.get("close") === "true" && params.get("moderator") !== "true") {
			setIsClosed(true);
		}

		if ((!!id && !!token) || !!superAdminToken) {
			const requestModerator = params.get("moderator") === "true";
			console.log({ isClosed });
			axios
				.post(`${process.env.REACT_APP_API_URL}/user/cle`, {
					idinexplore: id,
					token,
					superAdminToken,
					roomId: queryString,
				})
				.then((response) => {
					console.log(response);
					if (response.data.isSuperAdmin) {
						setSuperAdminToken(superAdminToken);
						setIsSuperAdmin(true);
					}
					if (response?.data?.cle) {
						setIdInexplore(id);
						setToken(response.data?.cle);
						setHasModeratorAccess(response.data?.isModerator);
						if (!!response.data?.isModerator && !!requestModerator) {
							setIsModerator(true);
						}
					} else {
						fetchLocalUser();
					}
				});
		} else {
			fetchLocalUser();
		}
	}, []);

	const values = React.useMemo(
		() => ({
			isAuthenticated,
			token,
			setUserInfo,
			userInfo,
			idInexplore,
			temporaryId,
			hasModerationAccess,
			isModerator,
			isSuperAdmin,
			superAdminToken,
			isClosed,
		}),
		[
			isAuthenticated,
			token,
			setUserInfo,
			userInfo,
			idInexplore,
			temporaryId,
			hasModerationAccess,
			isModerator,
			isSuperAdmin,
			superAdminToken,
			isClosed,
		],
	);

	return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};

export default AuthContextProvider;
